import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

const initialState:{
  data: { date: Date; orders: number, sales: number; }[],
  brandData: { brandName: string; orders: number, sales: number; }[],
  loading: boolean,
  error: boolean,
} = {
  data: [],
  brandData: [],
  loading: false,
  error: false,
}

type SalesProps = {
  locationUuid: string,
  date: string,
  period: string,
}

export const getSales = createAsyncThunk('sales/getSales', async (props: SalesProps) => {
  const { locationUuid, date, period } = props;
  const res = await axios.get('/sales?location_uuid='+locationUuid+'&date='+date+'&period='+period);
  return res.data;
});

export const getSalesByBrand = createAsyncThunk('sales/getSalesByBrand', async (props: SalesProps) => {
  const { locationUuid, date, period } = props;
  const res = await axios.get('/sales/brands?location_uuid='+locationUuid+'&date='+date+'&period='+period);
  return res.data;
});

const salesSlice = createSlice({
    name: 'sales',
    initialState,
    reducers: {
      resetSales: (state) => {
        state.data = [];
      },
    },
    extraReducers: (builder) => {
      builder.addCase(getSales.pending, (state, action) => {
        state.loading = true;
      });
      builder.addCase(getSales.fulfilled, (state, action) => {
        state.loading = false;
        let data = [];
        for (var i in action.payload.data) {
          data.push({
            'date': new Date(action.payload.data[i].label),
            'orders': action.payload.data[i].orders,
            'sales': action.payload.data[i].sales,
          })
        }
        state.data = data;
      });
      builder.addCase(getSalesByBrand.fulfilled, (state, action) => {
        state.loading = false;
        let data = [];
        for (var i in action.payload.data) {
          data.push({
            'brandName': action.payload.data[i].brand_name,
            'orders': action.payload.data[i].orders,
            'sales': action.payload.data[i].sales,
          })
        }
        state.brandData = data;
      });
      builder.addCase(getSales.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      });
    },
})

export const { resetSales } = salesSlice.actions

export const selectSales = (state: any) => {return state.sales.data};
export const selectSalesByBrand = (state: any) => {return state.sales.brandData};
export const selectSalesLoading = (state: any) => {return state.sales.loading};

export default salesSlice.reducer
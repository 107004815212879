import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import { Brand } from '../../model/model';

const initialState = {
  brands: [] as Brand[],
  loading: false,
  error: false,
  finished: false,
}

export const getAllBrands = createAsyncThunk('brands/getAllBrands', async (uuid: string) => {
  const res = await axios.get('/brands/all?organization_uuid='+uuid);
  return res.data;
});

type NewProps = {
  name: string,
  location_uuid: string,
}

export const newBrand = createAsyncThunk('brands/newBrand', async (props: NewProps) => {
  const res = await axios.post('/brands/new', props);
  return res.data;
});

type AddProps = {
  brand_uuid: string,
  location_uuid: string,
}

export const addBrand = createAsyncThunk('brands/addBrand', async (props: AddProps) => {
  const res = await axios.post('/brands/add', props);
  return res.data;
});

type FreeeProps = {
  brand_uuid: string,
  item_id: string,
}

export const connectBrandFreee = createAsyncThunk('brands/connectFreee',async (prop:FreeeProps) => {
  const res = await axios.post('/brands/freee', prop);
  return res.data;
});

const brandSlice = createSlice({
    name: 'brands',
    initialState,
    reducers: {
      resetBrands: (state) => {
        state = initialState;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(getAllBrands.pending, (state, action) => {
        state.loading = true;
      });
      builder.addCase(getAllBrands.fulfilled, (state, action) => {
        state.loading = false;
        state.brands = action.payload.data;
      });
      builder.addCase(getAllBrands.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      });
      builder.addCase(newBrand.pending, (state, action) => {
        state.loading = true;
        state.finished = false;
      });
      builder.addCase(newBrand.fulfilled, (state, action) => {
        state.loading = false;
        state.finished = true;
      });
      builder.addCase(newBrand.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      });
      builder.addCase(addBrand.pending, (state, action) => {
        state.loading = true;
        state.finished = false;
      });
      builder.addCase(addBrand.fulfilled, (state, action) => {
        state.loading = false;
        state.finished = true;
      });
      builder.addCase(addBrand.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      });
    },
})

export const { resetBrands } = brandSlice.actions

export const selectAllBrands = (state: any) => {return state.brands.brands};
export const selectBrandLoading = (state: any) => {return state.brands.loading};
export const selectBrandFinished = (state: any) => {return state.brands.finished};
export const selectBrandError = (state: any) => {return state.brands.error};

export default brandSlice.reducer
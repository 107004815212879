import Sidenav from '../components/Navigation';

import { useEffect, useState } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../store/store';
import { BottomNavigation, BottomNavigationAction, Box, Grid } from '@mui/material';
import { BarChart, CategoryOutlined, ChevronLeft, ChevronRight, MenuBookOutlined, StorefrontOutlined } from '@mui/icons-material';

import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import { getSales, getSalesByBrand, selectSales, selectSalesByBrand } from '../store/modules/sales';
import { selectLocation } from '../store/modules/locations';
dayjs.locale('ja');

const Sales = () => {
  const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();

  const [date, selectDate] = useState(dayjs());
  // const [date, selectDate] = useState(dayjs('2023-10-12'));
  const [period, setPeriod] = useState('day');
  const [dateTitle, setDateTitle] = useState(date.format('M月D日(dd)'));

  const setDate = (d: dayjs.Dayjs) => {
    selectDate(d);
  }
  const location = useSelector(selectLocation);
  useEffect(() => {
    if (location) {
      dispatch(getSales({locationUuid: location.uuid, date: date.format('YYYY-MM-DD'), period: period}));
      dispatch(getSalesByBrand({locationUuid: location.uuid, date: date.format('YYYY-MM-DD'), period: period}));
    }
  }, [location]);
  const dataset = useSelector(selectSales);
  const dataBrand = useSelector(selectSalesByBrand);

  // const dataset = [
  //   {
  //     'date': new Date('2023-10-06'),
  //     'sales': 70000,
  //   },
  //   {
  //     'date': new Date('2023-10-07'),
  //     'sales': 60000,
  //   },
  //   {
  //     'date': new Date('2023-10-08'),
  //     'sales': 50000,
  //   },
  //   {
  //     'date': new Date('2023-10-09'),
  //     'sales': 80000,
  //   },
  //   {
  //     'date': new Date('2023-10-10'),
  //     'sales': 50000,
  //   },
  //   {
  //     'date': new Date('2023-10-11'),
  //     'sales': 120000,
  //   },
  //   {
  //     'date': new Date('2023-10-12'),
  //     'sales': 100000,
  //   },
  // ]

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      let token = localStorage.getItem('token');
      if (!user || !token) {
        navigate('/signin');
      }
    });
  }, []);

  const setNewDate = (d: dayjs.Dayjs, p: string) => {
    var newTitle = d.format('M月D日(dd)');
    if (p == 'week') {
      newTitle = d.format('M月D日の週');
    } else if (p == 'month') {
      newTitle = d.format('YYYY年M月');
    }
    setDate(d);
    setDateTitle(newTitle);
  }

  const onPeriodChanged = (p:string) => {
    var newDate = dayjs();
    var newTitle = newDate.format('M月D日(dd)');
    if (p == 'week') {
      newDate = dayjs().add(-dayjs().add(-1, 'd').day(), 'd');
      newTitle = newDate.format('M月D日の週');
    } else if (p == 'month') {
      newDate = dayjs().add(1-dayjs().date(), 'd');
      newTitle = newDate.format('YYYY年M月');
    }

    setPeriod(p);
    setNewDate(newDate, p);
    dispatch(getSales({locationUuid: location.uuid, date: newDate.format('YYYY-MM-DD'), period: p}));
    dispatch(getSalesByBrand({locationUuid: location.uuid, date: newDate.format('YYYY-MM-DD'), period: p}));
  }

  const onPeriodBack = () => {
    var newDate = date.add(-1, 'd');
    if (period == 'week') {
      newDate = date.add(-7, 'd');
    } else if (period == 'month') {
      newDate = date.add(-1, 'M');
    }
    setNewDate(newDate, period);
    dispatch(getSales({locationUuid: location.uuid, date: newDate.format('YYYY-MM-DD'), period: period}));
    dispatch(getSalesByBrand({locationUuid: location.uuid, date: newDate.format('YYYY-MM-DD'), period: period}));
  }

  const onPeriodGo = () => {
    var newDate = date.add(1, 'd');
    if (period == 'week') {
      newDate = date.add(7, 'd');
    } else if (period == 'month') {
      newDate = date.add(1, 'M');
    }
    setNewDate(newDate, period);
    dispatch(getSales({locationUuid: location.uuid, date: newDate.format('YYYY-MM-DD'), period: period}));
    dispatch(getSalesByBrand({locationUuid: location.uuid, date: newDate.format('YYYY-MM-DD'), period: period}));
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box className="Second">
        <Box className='Button' onClick={onPeriodBack}>
          <ChevronLeft />
        </Box>
        <Box sx={{ width:'100%' }}>
          {dateTitle}
        </Box>
        <Box className='Button' onClick={onPeriodGo}>
          <ChevronRight />
        </Box>
      </Box>
      <Grid container sx={{
        position: 'fixed',
        zIndex: 1000,
        top: '88px',
        left: 0,
        height: '36px',
        width: '100%',
        display: 'flex',
        fontSize: '16px',
        textAlign: 'center',
        lineHeight: '36px',
      }}>
        <Grid item xs={4} onClick={() => onPeriodChanged('day')} sx={{
          borderBottom: 'solid 1px #d08f8f',
          borderRight: 'solid 1px #d08f8f',
          borderTop: period == 'day' ? 'solid 2px #a06e6e' : 'none',
          backgroundColor: period == 'day' ? '#d08f8f' : 'white',
          borderBottomLeftRadius: 10,
        }}>日</Grid>
        <Grid item xs={4} onClick={() => onPeriodChanged('week')} sx={{
          borderBottom: 'solid 1px #d08f8f',
          borderRight: 'solid 1px #d08f8f',
          borderTop: period == 'week' ? 'solid 2px #a06e6e' : 'none',
          backgroundColor: period == 'week' ? '#d08f8f' : 'white',
        }}>週</Grid>
        <Grid item xs={4} onClick={() => onPeriodChanged('month')} sx={{
          borderBottom: 'solid 1px #d08f8f',
          borderTop: period == 'month' ? 'solid 2px #a06e6e' : 'none',
          backgroundColor: period == 'month' ? '#d08f8f' : 'white',
          borderBottomRightRadius: 10,
        }}>月</Grid>
      </Grid>
      <Grid container mb='40px' mt='132px' gap='10px'>
        <Grid item xs={12} className="Card">
          <h2>売上サマリー</h2>
          
          {!dataset.length ? null :
            <Grid container>
              <Grid item xs={4} sx={{fontSize:20, fontWeight:600, textAlign: 'center'}}>{dataset[dataset.length-1].orders.toLocaleString()}件</Grid>
              <Grid item xs={4} sx={{fontSize:20, fontWeight:600, textAlign: 'center'}}>¥{dataset[dataset.length-1].sales.toLocaleString()}</Grid>
            </Grid>
          }
          
          <LineChart
            dataset={dataset}
            xAxis={[{
              dataKey: 'date',
              scaleType: 'utc',
              valueFormatter: (date) => dayjs(date).format("M/D"),
            }]}
            series={[
              {
                dataKey: 'sales',
                area: true,
              },
            ]}
            // width={500}
            height={240}
            margin={{ left: 70, right: 20, top: 30, bottom: 30 }}
          />
        </Grid>
        <Grid item xs={12} className="Card">
          <h2>ブランド別</h2>
          {dataBrand.map((row:{ brandName: string; orders: number, sales: number; }, i:number) => (
            <Grid container height={30} sx={{fontSize: 16}}>
              <Grid item sx={{fontWeight: 600, textAlign: 'left', width:'20px'}}>{i == 0 ? '🥇' : i == 1 ? '🥈' : i == 2 ? '🥉' : ''}</Grid>
              <Grid item xs sx={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{row.brandName}</Grid>
              <Grid item sx={{fontWeight: 600, textAlign: 'right', width:'80px'}}>¥{row.sales.toLocaleString()}</Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Sales;
import Sidenav from '../components/Navigation';
import { AppDispatch } from '../store/store';
import { selectOrganization, selectUserLoading } from '../store/modules/user';

import { useEffect, useRef, useState, forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, CircularProgress, Grid, List, ListItemButton, ListItemText, MenuItem, Modal, Select, SelectChangeEvent, Tab, Table, TableBody, TableCell, TableContainer, TableRow, Tabs } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';

import { BrandItem, Organization, StockCategory, StockIngredient, StockItem, StockPreparation } from '../model/model';
import { getIngredients, getItems, getOptions, getPreparations, postStockRow, selectBrandItems, selectBrandUuid, selectCategories, selectIngredients, selectOptions, selectPreparations, selectStockLoadings, setBrandUuid, setNewObject } from '../store/modules/stocks';
import Draggable from 'react-draggable';
import { AddOutlined, DragHandleOutlined } from '@mui/icons-material';
import StockView, { Props } from '../components/Stock/StockView';
import { getAllBrands } from '../store/modules/brands';

const Stock = () => {
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      let token = localStorage.getItem('token');
      if (!user || !token) {
        navigate('/signin');
      }
    });
  }, []);

  const [tabValue, setTabValue] = useState(localStorage.getItem('tab_value') ? localStorage.getItem('tab_value')! : 'ingredient');
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
    localStorage.setItem('tab_value', newValue);
  };

  const tabs = (
    <Grid item xs={12}>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        sx={{backgroundColor:'white'}}
      >
        {/* <Tab value="summary" label="サマリー" /> */}
        <Tab value="ingredient" label="材料" />
        <Tab value="preparation" label="仕込み" />
        <Tab value="item" label="商品" />
        <Tab value="option" label="オプション" />
      </Tabs>
    </Grid>
  );


  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const organization: Organization = useSelector(selectOrganization);

  const ingredients: StockIngredient[] = useSelector(selectIngredients);
  const preparations: StockPreparation[] = useSelector(selectPreparations);
  const brandItems: BrandItem[] = useSelector(selectBrandItems);
  const options: StockItem[] = useSelector(selectOptions);
  const brandUuid: string = useSelector(selectBrandUuid);
  useEffect(() => {
    var localUuid = localStorage.getItem('brand_uuid');
    if (localUuid) {
      if (brandItems.filter((b:BrandItem) => b.brand_uuid == localUuid).length > 0) {
        dispatch(setBrandUuid(localUuid!))
      }
      return;
    }
    if (brandItems.length > 0) {
      dispatch(setBrandUuid(brandItems[0].brand_uuid!))
    }
  }, [brandItems]);

  const loading: boolean = useSelector(selectUserLoading);
  const categories: StockCategory[] = useSelector(selectCategories);
  const stockLoadings = useSelector(selectStockLoadings);

  // fetch item list
  useEffect(() => {
    if (organization) {
      dispatch(getAllBrands(organization.uuid));
      if (ingredients.length == 0) {
        dispatch(getIngredients(organization.uuid));
      }
      if (preparations.length == 0) {
        dispatch(getPreparations(organization.uuid));
      }
      if (brandItems.length == 0) {
        dispatch(getItems(organization.uuid));
      }
      if (options.length == 0) {
        dispatch(getOptions(organization.uuid));
      }
    }
  }, [organization]);

  useEffect(() => {
    if (!stockLoadings.post && showModal) {
      setShowModal(false);
      if (tabValue == 'ingredient') {
        dispatch(getIngredients(organization.uuid));
      }
      if (tabValue == 'preparation') {
        dispatch(getPreparations(organization.uuid));
      }
      if (tabValue == 'item') {
        dispatch(getItems(organization.uuid));
      }
      if (tabValue == 'option') {
        dispatch(getOptions(organization.uuid));
      }
    }
  }, [stockLoadings.post]);


  const [showModal, setShowModal] = useState(false);
  const [path, setPath] = useState('');
  const [categoryUuid, setCategoryUuid] = useState('all');
  const [item, setItem] = useState(new StockItem());
  const [option, setOption] = useState(new StockItem());
  const [preparation, setPreparation] = useState(new StockPreparation());
  const [ingredient, setIngredient] = useState(new StockIngredient());
  const [category, setCategory] = useState(new StockCategory());
  

  

  const onStop = () => {
    if (dragIndex != 0) {
      const newRow = ingredient.row + dragIndex;
      var param = [];
      var items = [];
      for (var ing of ingredients) {
        if (ing.row == ingredient.row) {
          items.push({...ing, row: newRow});
          param.push({type: 'ingredient', uuid: ing.uuid!, row: newRow});
        } else if (ing.row > ingredient.row && ing.row <= newRow) {
          items.push({...ing, row: ing.row - 1});
          param.push({type: 'ingredient', uuid: ing.uuid!, row:ing.row - 1});
        } else if (ing.row < ingredient.row && ing.row >= newRow) {
          items.push({...ing, row: ing.row + 1});
          param.push({type: 'ingredient', uuid: ing.uuid!, row:ing.row + 1});
        } else {
          items.push(ing);
        }
      }
      items.sort((i, j) => i.row - j.row);
      dispatch(setNewObject({ingredients:items}));
      dispatch(postStockRow(param));
    }
  }

  const [dragIndex, setDragIndex] = useState(0);
  const displayRow = (item: any, row: number) => {
    const length = 
      tabValue == 'ingredient' ? ingredients.length :
      tabValue == 'preparation' ? preparations.length :
      tabValue == 'item' ? brandItems.filter((b:BrandItem) => b.brand_uuid == brandUuid)[0].items.length :
      tabValue == 'option' ? options.length : 0;
    return (
      <TableRow
        sx={{display: 'flex'}}
        key={item.uuid}
      >
        <TableCell sx={{
          p: 0,
          width: '100%',
        }}>
          <Draggable
            handle=".handle"
            axis="y"
            defaultPosition={{x: 0, y: 0}}
            position={{x:0, y: 0}}
            bounds={{top: -row*30, bottom: (length-row-1)*30}}
            scale={1}
            onDrag={(e, position) => {
              const {x, y} = position;
              const newIndex = Math.round(y / 30);
              if (dragIndex != newIndex) {
                setDragIndex(newIndex);
              }
            }}
            onStop={onStop}
          >
            <List component="div" disablePadding>
              <Grid container>
                { (tabValue == 'ingredient' && categoryUuid == 'all') ? null :
                  <Grid item className="handle" xs='auto' sx={{height: '30px', width:'50px !important', textAlign: 'center'}}>
                    <DragHandleOutlined sx={{height: '30px'}} />
                  </Grid>
                }
                <Grid item xs>
                  <ListItemButton sx={{ p: 0, pl: '5px', pr:'20px', height: '30px' }} onClick={() => {
                    // console.log(item.name);
                    setPath(tabValue);
                    if (tabValue == 'ingredient') {
                      setIngredient(item as StockIngredient);
                    }
                    if (tabValue == 'preparation') {
                      setPreparation(item as StockPreparation);
                    }
                    if (tabValue == 'item') {
                      setItem(item as StockItem);
                    }
                    if (tabValue == 'option') {
                      setOption(item as StockItem);
                    }
                    setShowModal(true);
                  }}>
                    <Grid item xs sx={{height: '30px', display: 'inline-grid'}}>
                      <ListItemText sx={{height: '30px',whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}} primary={item.name} />
                    </Grid>
                    <Grid item xs='auto' sx={{minWidth: '70px', height: '30px', textAlign: 'right'}}>
                      <ListItemText primary={'¥ ' + Math.round(item.total_cost).toLocaleString()} />
                    </Grid>
                  </ListItemButton>
                </Grid>
              </Grid>
            </List>
          </Draggable>
        </TableCell>
      </TableRow>
    );
  }

  const Ref = forwardRef<HTMLDivElement, Props>(
    ({ path, item, option, brandUuid, preparation, ingredient, category }, ref) => {
      return <StockView
        children={{
          path: path,
          item: item,
          option: option,
          brandUuid: brandUuid,
          preparation: preparation,
          ingredient: ingredient,
          category: category,
        }}
        forwardRef={ref}
      />;
    }
  );


  const handleCategoryChange = (event: SelectChangeEvent) => {
    if (event.target.value == 'new') {
      setCategory(new StockCategory());
      setPath('category');
      setShowModal(true);
    } else {
      setCategoryUuid(event.target.value);
    }
  }

  const handleBrandChange = (event: SelectChangeEvent) => {
    console.log(event.target.value)
    localStorage.setItem('brand_uuid', event.target.value as string);
    dispatch(setBrandUuid(event.target.value as string))
  }
  
  const select = (
    <Grid container sx={{
      backgroundColor: 'white',
      margin: 0,
      zIndex: 1000,
      p: '10px 0',
      maxWidth: '400px'
    }}>
      {tabValue == 'ingredient' ?
        <Select
          sx={{width: '100%'}}
          labelId="category-select-label"
          id="category-select"
          value={categoryUuid}
          defaultValue={'all'}
          label="Brand"
          onChange={handleCategoryChange}
        >
          <MenuItem value={'all'} key={'all'}>全て表示</MenuItem>
          {categories
            .map((c: StockCategory) => (
              <MenuItem value={c.uuid} key={c.uuid}>{c.name}</MenuItem>
            ))}
          <MenuItem value={'none'} key={'none'}>カテゴリーなし</MenuItem>
          <MenuItem value={'new'} key={'new'}>カテゴリーを追加</MenuItem>
        </Select> :
        <Select
          sx={{width: '100%'}}
          labelId="brand-select-label"
          id="brand-select"
          value={brandUuid}
          label="Brand"
          onChange={handleBrandChange}
        >
          {brandItems
            .map((b: BrandItem) => (
              <MenuItem value={b.brand_uuid!} key={b.brand_uuid!}>{b.brand_name}</MenuItem>
          ))}
        </Select>
      }
    </Grid>
  );

  const newButton = (
    <Box sx={{
      position: 'absolute',
      bottom: '75px',
      right: '20px',
      width: '40px',
      height: '40px',
      padding: '10px',
      borderRadius: '50%',
      backgroundColor: '#EC7281',
      zIndex: 1000,
      boxShadow: '5px 5px 5px 0 rgba(0, 0, 0, .8)',
    }}>
      <AddOutlined sx={{height: '40px', width: '40px'}}  onClick={() => {
        setPath(tabValue);
        setIngredient(new StockIngredient({category_uuid: categoryUuid}));
        setPreparation(new StockPreparation());
        setItem(new StockItem({brand_uuid: brandUuid}));
        setOption(new StockItem());
        setShowModal(true);
      }} />
    </Box>
  );

  const modal = (
    <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        sx={{
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Ref 
          path={path}
          item={item}
          option={option}
          brandUuid={''}
          preparation={preparation}
          ingredient={ingredient}
          category={category}
        />
      </Modal>
  );

  return (
    <>
      <Sidenav />
      <Box className="Second">
        <Box sx={{ width:'100%' }}>
          在庫管理
        </Box>
      </Box>
      <Grid container mb='40px' mt='88px' gap='10px' sx={{ overflow: 'hidden', alignContent: 'baseline' }}>
        { tabs }
        
        {/* contents */}
        <Grid item className='Card' xs={12} sx={{
          justifyContent: 'center',
          height: 'calc(100vh - 220px)',
          // pt: 0,
          mb: '80px',
        }}>
        {
          loading ?
            <div style={{textAlign:'center'}}><CircularProgress /></div> :
            <>
              { (tabValue == 'ingredient' || tabValue == 'item') ? select : null }
              { newButton }
              <TableContainer sx={{
                width: '100%',
                maxWidth: '1000px',
                height: (tabValue == 'ingredient' || tabValue == 'item') ?  'calc(100vh - 310px)' : 'calc(100vh - 240px)',
                overflowY: 'auto',
              }}>
                <Table sx={{mb: '80px'}}>
                  <TableBody>
                    {(tabValue == 'ingredient' ? ingredients
                      .filter((ing: StockIngredient) => categoryUuid == 'all' ? true : categoryUuid == 'none' ? !ing.category_uuid : ing.category_uuid == categoryUuid) :
                    tabValue == 'preparation' ? preparations :
                    tabValue == 'item' ? brandItems.filter((b:BrandItem) => 
                        b.brand_uuid == brandUuid).length == 0 ? [] : 
                        brandItems.filter((b:BrandItem) => b.brand_uuid == brandUuid)[0].items :
                    tabValue == 'option' ? options : [])
                      .map(displayRow)}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
        }
        </Grid>
      </Grid>
      {modal}
    </>
  );
};

export default Stock;
import Sidenav from '../components/Navigation';
import CredentialView, { Props } from '../components/CredentialView'
import { AppDispatch } from '../store/store';
import { Brand, Credential, Location, Organization } from '../model/model';
import { getLocations, setLocation, selectLocations, selectLocationLoading, selectLocation } from '../store/modules/locations';
import { selectStores, selectStoreLoading, getStoreList } from '../store/modules/stores';

import { useEffect, useRef, useState, forwardRef } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Drawer from '@mui/material/Drawer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress'
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, MenuItem, Select, SelectChangeEvent, TableCell } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';

import ubereats from '../assets/ubereats.png'
import demaecan from '../assets/demaecan.png'
import menu from '../assets/menu.png'
import wolt from '../assets/wolt.png'
import square from '../assets/square.png'
import takeme from '../assets/takeme.png'
import { AddOutlined, AddBoxOutlined, WarningOutlined } from '@mui/icons-material';
import { resetCredential, selectCredentialLoading, selectCredentialFinished, setCredential } from '../store/modules/credentials';
import { disconnectFreee, selectOrganization, selectUserLoading } from '../store/modules/user';
import { getAllBrands, selectBrandFinished } from '../store/modules/brands';

const img = (slug:string) => {
  switch(slug) {
    case 'ubereats': return ubereats;
    case 'demaecan': return demaecan;
    case 'menu': return menu;
    case 'wolt': return wolt;
    case 'square': return square;
    case 'takeme': return takeme;
    default: return '';
  }
}

const Credentials = () => {
  const locations: Location[] = useSelector(selectLocations);
  const location: Location = useSelector(selectLocation);
  const brands: Brand[] = useSelector(selectStores);
  const organization: Organization = useSelector(selectOrganization);

  const storeLoading: boolean = useSelector(selectStoreLoading);
  const brandFinished: boolean = useSelector(selectBrandFinished);
  const credentialLoading: boolean = useSelector(selectCredentialLoading);
  const credentialFinished: boolean = useSelector(selectCredentialFinished);
  const locationLoading: boolean = useSelector(selectLocationLoading);
  const userLoading: boolean = useSelector(selectUserLoading);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    if (location) {
      dispatch(getStoreList(location.uuid));
      dispatch(getAllBrands(organization.uuid));
    }
  }, [location]);

  useEffect(() => {
    if ((credentialFinished || brandFinished) && showModal) {
      modalClose();
      dispatch(getStoreList(location.uuid));
    }
  }, [credentialFinished, brandFinished]);

  const handleLocationChange = (event: SelectChangeEvent) => {
    dispatch(setLocation(event.target.value as string));
  }

  const [showModal, setShowModal] = useState(false);
  const [brand, setBrand] = useState(null as Brand | null);
  const [storeUUid, setStoreUuid] = useState('');

  const modalOpen = (brand: Brand | null, cred: Credential | null) => {
    setBrand(brand);
    dispatch(setCredential(cred));
    setShowModal(true);
  };

  const modalClose = () => {
    setShowModal(false);
    setBrand(new Brand());
    dispatch(resetCredential());
  }

  const displayCredential = (brand: Brand, cred: Credential) => {
    return (
    <Grid item sx={{position: 'relative', width:'40px', height: '40px', mr: '10px'}} onClick={() => modalOpen(brand, cred)}>
      <img src={img(cred.ofo)} style={{
        width:'40px',
        height: '40px',
        borderRadius: '10%',
        zIndex: -10,
        border: cred.ofo == 'menu' ? 'solid 1px gray' : 'none',
        filter: (cred.app?.status == 'INACTIVE' && cred.web?.status == 'INACTIVE') ? 'grayscale(100%)' : 'none',
      }} />
      
      {   (cred.app?.status == 'INVALID_CREDENTIAL'
        || cred.app?.status == 'ERROR') ? 
          <WarningOutlined color='error' style={{ position: 'absolute', right: '-8px', top: '-8px', width:'20px', height: '20px' }} />
      
        : (cred.web?.status == 'INVALID_CREDENTIAL'
        || cred.web?.status == 'ERROR') ? 
          <WarningOutlined color='warning' style={{ position: 'absolute', right: '-8px', top: '-8px', width:'20px', height: '20px' }} />
        : <></>
      }
    </Grid>
    );
  }

  const displayBrand = (brand: Brand) => {
    return (
      <TableRow key={brand.uuid} sx={{
        m: '10px auto',
        display: 'flex',
        boxSizing: 'border-box'
      }}>
        <TableCell className='Card' sx={{
          p: '5px',
          width: '100%',
          overflow: 'hidden',
          boxSizing: 'border-box'
        }}>
          <Grid container>
            <Grid item xs={12} sm={6} sx={{
              p: '5px 10px',
              fontSize: '16px',
              fontWeight: 600,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {brand.name}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container sx={{
                p: '5px 10px',
                justifyContent: 'right',
                boxSizing: 'border-box'
              }}>
                {brand.credentials.map((cred: Credential) => displayCredential(brand, cred))}
                <Grid item sx={{position: 'relative'}} onClick={() => modalOpen(brand, new Credential())}>
                  <AddBoxOutlined style={{ width:'40px', height: '40px' }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    );
  }
  

  const Ref = forwardRef<HTMLDivElement, Props>(
    ({brand}, ref) => {
      return <CredentialView brand={brand} forwardRef={ref} />;
    }
  );

  const newButton = (
    <Box sx={{
      position: 'absolute',
      bottom: '75px',
      right: '20px',
      width: '40px',
      height: '40px',
      padding: '10px',
      borderRadius: '50%',
      backgroundColor: '#EC7281',
      zIndex: 1000,
      boxShadow: '5px 5px 5px 0 rgba(0, 0, 0, .8)',
    }}>
      <AddOutlined sx={{height: '40px', width: '40px'}}  onClick={() => {
        modalOpen(null, new Credential());
      }} />
    </Box>
  );
  return (
    <Box sx={{
      height: 'calc(100vh - 66px)',
      overflow: 'auto',
    }}>
      <Sidenav />
      <Box className="Second">
        <Box sx={{ width:'100%' }}>
          アカウント連携
        </Box>
      </Box>
      <Grid container mb='40px' mt='88px' gap='10px'>
        {( userLoading || storeLoading || locationLoading) ?
          <div style={{textAlign:'center'}}><CircularProgress /></div> :
          <>
            {/* <Grid item xs={6} sx={{display: 'flex', flexFlow: 'row-reverse'}}>
              { organization?.freee_active ? 
                <Button onClick={() => navigate('/freee')}>Freee Settings</Button> :
                <Button href='https://accounts.secure.freee.co.jp/public_api/authorize?client_id=1307b0e35caabb461836f8ab7738c47118a7caba49980684026eaa55a3d52c83&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Ffreee&response_type=code&prompt=select_company'>Connect Freee</Button>
              }
            </Grid> */}
            <Grid item xs={12} sx={{
              display: 'flex',
              justifyContent: 'center',
              p: '10px',
            }}>
              <TableContainer>
                <Table sx={{
                  width: '100%',
                  tableLayout: 'fixed',
                }}>
                  <TableBody sx={{gap: '10px'}}>
                    { brands.map(displayBrand) }
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </>
        }
      </Grid>
      {newButton}
      <Modal
        open={showModal}
        onClose={() => modalClose()}
        sx={{
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Ref brand={brand} />
      </Modal>
    </Box>
  );
};

export default Credentials;
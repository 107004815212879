import { BrowserRouter, Routes, Route } from "react-router-dom";
import Top from "./views/Top";
import Signin from "./views/Signin";
import Sales from "./views/Sales";
import axios from 'axios';
import { auth } from './firebase';
import Credentials from "./views/Credentials";
import Freee from "./views/Freee";
import Menu from "./views/Menu";
import Stock from "./views/Stock";
import grid from './assets/grid.png'
import { createTheme, ThemeProvider } from "@mui/material";
import Navigation from './components/Navigation';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL
axios.interceptors.request.use((config) => {
  if (config.headers !== undefined) {
    config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`
  }
  return config
})
axios.interceptors.response.use(
  function (response) {
    return response
  },
  async function (error) {
    if (!error.response) {
      console.log(`Error: ${error.message}`);
      return Promise.reject(error)
    }
    if (error.response.status === 400 || error.response.status === 401 || error.response.status === 403) {
      if (error.response.data.message === 'token expired') {
        const user = auth.currentUser
        if (user != null) {
          const token = await user.getIdToken(true)
          localStorage.setItem('token', token)
          error.config.headers['Authorization'] = `Bearer ${token}`
          return axios.request(error.config)
        }
      }
      auth
        .signOut()
        .then(() => {
          localStorage.removeItem('token')
        })
      return Promise.reject(error)
    }
    const title = navigator.language === 'ja' ? 'エラー' : 'Error'
    // const message = navigator.language === 'ja' ? '処理に失敗しました' : 'Failed to process.'
    console.log(`${title}: ${error.message}`);
    // ElMessageBox.alert(message, title, { dangerouslyUseHTMLString: false, confirmButtonText: 'OK' })
    return Promise.reject(error)
  }
)

const theme = createTheme({
  palette: {
    primary: {
      light: '#876666',
      main: '#e84566',
      dark: '#9E6262',
      contrastText: '#000',
    },
    secondary: {
      light: '#F09D97',
      main: '#a06e6e',
      dark: '#EC7281',
      contrastText: '#000',
    },
  },
});


const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <div className="App" style={{ 
        background: `url(${grid})`
      }}>
      <BrowserRouter>
        <Routes>
          <Route path={`/`} element={<Top />} />
          <Route path={`/signin/`} element={<Signin />} />
          <Route path={`/sales`} element={<><Sales /><Navigation /></>} />
          <Route path={`/menu`} element={<><Menu /><Navigation /></>} />
          <Route path={`/stock`} element={<><Stock /><Navigation /></>} />
          <Route path={`/credentials`} element={<><Credentials /><Navigation /></>}  />
          <Route path={`/freee`} element={<Freee />}  />
        </Routes>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
};

export default App;
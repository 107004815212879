
import { useEffect, useState, forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Draggable from 'react-draggable';

import { Button, CircularProgress, Collapse, List, ListItemButton, ListItemText, Paper, Grid, Modal, Table, TableBody, TableContainer, TableRow, TableCell } from '@mui/material';
import { AddOutlined, DragHandleOutlined, ExpandLess, ExpandMore } from '@mui/icons-material';

import { Item, ItemSection } from '../../model/model';
import { AppDispatch } from '../../store/store';
import { setItemSectionOpens, getSectionItems, selectItemLoading, selectItemSectionOpens, selectItemSections, postItemRow, setNewObject, selectAllItems, resetItemSectionOpens } from '../../store/modules/items';

import MenuItemView, { Props } from './MenuItemView';

const MenuItems = () => {
  const allItems: Item[] = useSelector(selectAllItems);
  const itemSections: ItemSection[] = useSelector(selectItemSections);
  const itemSectionOpens: boolean[] = useSelector(selectItemSectionOpens);
  const itemLoading: boolean = useSelector(selectItemLoading);

  useEffect(() => {
    if (!itemLoading && showModal) {
      setShowModal(false);
    }
  }, [itemLoading]);

  const [showModal, setShowModal] = useState(false);
  const [path, setPath] = useState('');
  const [section, setSection] = useState(new ItemSection());
  const [item, setItem] = useState(new Item());
  const onClickElement = (section?: ItemSection, item?: Item ) => {
    if (item) {
      setPath('item');
      if (section) {
        setSection(section)
      }
      setItem(item);
    } else if (section) {
      setPath('section');
      setSection(section);
    } else  {
      return;
    }
    setShowModal(true);
  }

  const itemHeight = 50;
  const sectionHeight = itemHeight + 12;
  const [dragIndex, setDragIndex] = useState(0);
  const dispatch = useDispatch<AppDispatch>();
  const renderItem = (it: Item, itIndex: number, secIndex: number) => {
    let selectedSection = itemSections[secIndex];
    if (!selectedSection) {
      return null;
    }

    let item: Item;
    let filteredItems = allItems.filter((i: Item) => i.uuid == it.uuid);
    if (filteredItems.length > 0) {
      item = {...filteredItems[0], image_url: it.image_url, row: it.row};
    } else {
      item = it;
    }

    return (
      <Draggable
        key={item.uuid}
        handle=".handle"
        axis="y"
        defaultPosition={{x: 0, y: 0}}
        position={{x:0, y: 0}}
        bounds={{top: -itIndex*itemHeight, bottom: (selectedSection.items.length-itIndex)*itemHeight}}
        scale={1}
        onDrag={(_, position) => {
          const y = position.y;
          let newIndex = Math.round(y / itemHeight);
          if (dragIndex != newIndex) {
            setDragIndex(newIndex);
          }
        }}
        onStop={() => {
          if (dragIndex != 0) {
            let curRow = item.row;
            let newRow = item.row + dragIndex;
            let setItems = [];
            let params = [];
            for (var it of selectedSection.items) {
              if (it.row == curRow) {
                setItems.push({...it, row: newRow});
                params.push({type: 'item', uuid: it.section_item_uuid!, row: newRow});
              } else if (it.row > curRow && it.row <= newRow) {
                setItems.push({...it, row: it.row - 1});
                params.push({type: 'item', uuid: it.section_item_uuid!, row:it.row - 1});
              } else if (it.row < curRow && it.row >= newRow) {
                setItems.push({...it, row: it.row + 1});
                params.push({type: 'item', uuid: it.section_item_uuid!, row:it.row + 1});
              } else {
                setItems.push(it);
              }
            }
            setItems.sort((i, j) => i.row - j.row);
            dispatch(setNewObject({index: secIndex, items: setItems}));
            dispatch(postItemRow(params));
          }
        }}
      >
        <List component="div" disablePadding>
          <Grid container>
            <Grid item className="handle" xs='auto' sx={{height: itemHeight+'px', width: itemHeight+'px !important', textAlign: 'center'}}>
              <DragHandleOutlined sx={{height: itemHeight+'px'}} />
            </Grid>
            <Grid item xs>
              <ListItemButton sx={{ p:0, pr:'20px', height: itemHeight+'px' }} onClick={() => onClickElement(section, item)}>
                <Grid item xs={8} sx={{display: 'flex', alignItems: 'center'}}>
                {item.image_url ?
                  <img src={item.image_url} style={{width: itemHeight+'px', height: itemHeight+'px', objectFit: 'contain', marginRight: '10px'}} /> :
                  <div style={{width: itemHeight+'px', height: itemHeight+'px', objectFit: 'contain', marginRight: '10px'}} />
                }
                <ListItemText primary={item.name} />
                </Grid>
                <Grid item xs={2} sx={{textAlign: 'right'}}>
                  <ListItemText primary={item.takeout_price} />
                </Grid>
                <Grid item xs={2} sx={{textAlign: 'right'}}>
                  <ListItemText primary={item.delivery_price} />
                </Grid>
              </ListItemButton>
            </Grid>
          </Grid>
        </List>
      </Draggable>
    )
  }

  const renderItemSection = (section: ItemSection, secIndex: number) => {
    return (
      <Draggable
        key={section.uuid}
        handle=".sectionhandle"
        axis="y"
        defaultPosition={{x: 0, y: 0}}
        position={{x:0, y: 0}}
        bounds={{top: -secIndex*sectionHeight, bottom: (itemSections.length-secIndex-1)*sectionHeight}}
        scale={1}
        onDrag={(_, position) => {
          dispatch(resetItemSectionOpens());
          const y = position.y;
          let newIndex = Math.round(y / sectionHeight);
          if (dragIndex != newIndex) {
            setDragIndex(newIndex);
          }
        }}
        onStop={() => {
          if (dragIndex != 0) {
            let curRow = section.row;
            let newRow = section.row + dragIndex;
            let setItems = [];
            let params = [];
            for (var sec of itemSections) {
              if (sec.row == curRow) {
                setItems.push({...sec, row: newRow});
                params.push({type: 'section', uuid: sec.uuid!, row: newRow});
              } else if (sec.row > curRow && sec.row <= newRow) {
                setItems.push({...sec, row: sec.row - 1});
                params.push({type: 'section', uuid: sec.uuid!, row:sec.row - 1});
              } else if (sec.row < curRow && sec.row >= newRow) {
                setItems.push({...sec, row: sec.row + 1});
                params.push({type: 'section', uuid: sec.uuid!, row:sec.row + 1});
              } else {
                setItems.push(sec);
              }
            }
            setItems.sort((i, j) => i.row - j.row);
            dispatch(setNewObject({index: secIndex, sections: setItems}));
            dispatch(postItemRow(params));
          }
        }}
      >
        <TableRow
          sx={{m: '10px auto', display: 'flex'}}
          key={section.uuid}
        >
          <TableCell sx={{
            p:'0',
            width: '100%',
            background: 'white',
            border:'1px solid black',
            borderRadius: '4px',
            overflow: 'hidden'
          }}>
            <Grid container>
              <Grid item className="sectionhandle" xs='auto' sx={{height: itemHeight+'px', width: itemHeight+'px !important', textAlign: 'center'}}>
                <DragHandleOutlined sx={{height: itemHeight+'px'}} />
              </Grid>
              <ListItemButton>
                <ListItemText primary={section.name} onClick={() => onClickElement(section)}/>
              </ListItemButton>
              <Button onClick={() => dispatch(setItemSectionOpens(secIndex))}>
                { itemSectionOpens[secIndex] ? <ExpandLess /> : <ExpandMore /> }
              </Button>
            </Grid>
            <Collapse in={itemSectionOpens[secIndex]} timeout="auto" unmountOnExit>
              { section.items.map((item: Item, itIndex: number) => renderItem(item, itIndex, secIndex)) }
              <ListItemButton sx={{ background: '#a06e6e' }} onClick={() => onClickElement(section, new Item())}>
                <AddOutlined sx={{ m: 'auto' }} />
              </ListItemButton>
            </Collapse>
          </TableCell>
        </TableRow>
      </Draggable>
    );
  }

  const Ref = forwardRef<HTMLDivElement, Props>(
    ({ path, section, item, optiongroup, option }, ref) => {
      return <MenuItemView
        children={{
          path: path,
          section: section,
          item: item,
          optiongroup: optiongroup,
          option: option,
        }}
        forwardRef={ref}
      />;
    }
  );

  return (
    <>
      <Grid item xs={12} sx={{
        display: 'flex',
        justifyContent: 'center',
        height: 'calc(100vh - 140px)',
        paddingTop: '20px',
        overflowY: 'auto',
      }}>
        {itemLoading ?
          <div style={{textAlign:'center'}}><CircularProgress /></div> :
        
          <TableContainer component={Paper} sx={{
            bgcolor: 'transparent',
            width: '100%',
            maxWidth: '1000px',
          }}>
            <Table aria-label='simple table'>
              <TableBody>
                { itemSections
                  .map((section:ItemSection, index: number) => renderItemSection(section, index))
                }
                  <TableRow
                    sx={{m: '10px auto', display: 'flex'}}
                    key='new'
                  >
                    <TableCell sx={{
                      p:'0',
                      width: '100%',
                      background: '#E84566',
                      borderRadius: '4px',
                      overflow: 'hidden',
                    }}>
                      <ListItemButton onClick={() => onClickElement(new ItemSection({row: itemSections.length + 1}))}>
                        <AddOutlined sx={{ m: 'auto' }} />
                      </ListItemButton>
                    </TableCell>
                  </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        }
      </Grid>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        sx={{
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Ref 
          path={path}
          section={section}
          item={item}
          optiongroup={null}
          option={null}
        />
      </Modal>
    </>
  );
};

export default MenuItems;
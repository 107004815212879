import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios';
import { Credential, CredentialInput, SelectInput } from '../../model/model';

const initialState = {
  credential: null as Credential | null,
  stores : [],
  isFinished: false,
  loading: false,
  error: undefined as string | undefined,
}

export const postCredential = createAsyncThunk('credentials', async (prop:CredentialInput) => {
  const res = await axios.post('/credentials', prop);
  return res.data;
});

export const postStoreSelect = createAsyncThunk('credentials', async (prop:SelectInput) => {
  const res = await axios.post('/credentials/select', prop);
  return res.data;
});

const credentialsSlice = createSlice({
    name: 'credentials',
    initialState,
    reducers: {
      setCredential: (state, action: PayloadAction<Credential | null>) => {
        state.credential = action.payload;
      },
      resetCredential: (state) => {
        state.credential = null;
      },
      resetStores: (state) => {
        state.stores = [];
      },
    },
    extraReducers: (builder) => {
      builder.addCase(postCredential.pending, (state, action) => {
        state.loading = true;
        state.isFinished = false;
      });
      builder.addCase(postCredential.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.message == 'select') {
          state.stores = action.payload.data;
        } else {
          state.credential = action.payload.data;
          if (action.payload.data.app?.status == 'ACTIVE' || action.payload.data.web?.status == 'ACTIVE') {
            state.isFinished = true;
          }
        }
      });
      builder.addCase(postCredential.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    },
})

export const { setCredential, resetCredential, resetStores } = credentialsSlice.actions

export const selectCredential = (state: any) => {return state.credentials.credential};
export const selectStores = (state: any) => {return state.credentials.stores};
export const selectCredentialLoading = (state: any) => {return state.credentials.loading};
export const selectCredentialFinished = (state: any) => {return state.credentials.isFinished};
export const selectCredentialError = (state: any) => {return state.credentials.error};

export default credentialsSlice.reducer
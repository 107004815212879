

export const PriceFormat = (num: number, disit: number) => {
    var s = String(num).split('.');
    var ret = String(s[0]).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    if (s.length > 1 && disit > 0) {
        ret += '.' + ((' '.repeat(disit)) + s[1]).slice(-disit);
    }
    return '¥' + ret;
  }

export const DisplayNumber = (number: number, unit: string) => {
    return (number > 100 ? Math.round(number) : Math.round(number * 100) / 100).toLocaleString() + unit;
}
import React, { useEffect, forwardRef, useState } from 'react';
import { Box, Button, Grid, Divider, CircularProgress, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';

import { Brand, Store, SelectInput, Credential, CredentialInput, Location, Organization } from '../model/model';

import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch } from '../store/store';
import { addBrand, getAllBrands, newBrand, selectAllBrands, selectBrandLoading } from '../store/modules/brands';
import { postCredential, selectStores, selectCredential, selectCredentialLoading, setCredential, postStoreSelect, resetStores, selectCredentialError } from '../store/modules/credentials';
import { selectLocation } from '../store/modules/locations';
import { selectOrganization } from '../store/modules/user';

export interface Props {
  brand: Brand | null;
  forwardRef?: React.Ref<HTMLDivElement>;
}

const OfoList: {
  slug: string;
  name: string;
}[] = [{
  slug: 'ubereats',
  name: 'Uber Eats',
},{
  slug: 'demaecan',
  name: '出前館',
}, {
  slug: 'menu',
  name: 'menu',
}, {
  slug: 'wolt',
  name: 'Wolt',
}, {
  slug: 'square',
  name: 'Square',
}, {
  slug: 'takeme',
  name: 'TakeMe',
}];

const CredentialView = ({ brand, forwardRef }: Props) => {
  const location: Location = useSelector(selectLocation);

  const credential : Credential = useSelector(selectCredential);
  const loading: boolean = useSelector(selectCredentialLoading);
  const credError: string | undefined = useSelector(selectCredentialError);
  
  const brands : Brand[] = useSelector(selectAllBrands);
  const brandLoading: boolean = useSelector(selectBrandLoading);

  const [brandName, setBrandName] = useState('');

  const stores : Store[] = useSelector(selectStores);
  const dispatch = useDispatch<AppDispatch>();
  const handleStoreChange = (e: SelectChangeEvent) => {
    dispatch(postStoreSelect(new SelectInput({
      store_uuid: brand!.store_uuid,
      ofo_slug: credential.ofo,
      store: new Store({
        id: e.target.value as string,
        name: stores.filter((s:Store) => s.id == e.target.value)[0].name,
      })}))
    );
    dispatch(resetStores());
  }

  
  const statusText = (obj: { code: string | null; id: string | null; password: string | null; status: string | null; }) => {
    if (!obj) { return '連携なし' }
    var { status } = obj;
    if (status == 'INACTIVE') {
      return '連携なし';
    }
    if (status == 'ERROR') {
      return '不明なエラー';
    }
    if (status == 'INVALID_CREDENTIAL') {
      return 'ログイン情報が誤っています';
    }
    if (status == 'NOT_FOUND') {
      return 'アカウントが見つかりません';
    }
    if (status == 'ACTIVE') {
      return '連携中';
    }
    return '';
  }

  const handleButton = () => {
    var input = new CredentialInput(
      {
        store_uuid: brand!.store_uuid,
        ofo_slug: credential.ofo,
        credential_input: {
          app_code: credential.app?.code,
          app_email: credential.app?.id,
          app_password: credential.app?.password,
          web_code: credential.web?.code,
          web_email: credential.web?.id,
          web_password: credential.web?.password,
          store_url: credential.store?.url,
        }
      }
    )
    dispatch(postCredential(input))
  }

  return (
    <>
      <Box className='FontM' sx={{
        display: 'flex',
        flexFlow: 'column',
        width: '100%',
        maxWidth: '1000px',
        maxHeight: '80vh',
        background: 'white',
        overflow: 'hidden',
        p: '10px',
        m: '20px',
      }}>
        { brand ? 
          <Grid container mb='10px'>
            <Grid item xs={12}>{brand.name}</Grid>
            { credential.ofo == '' ? 
              <Select
              sx={{width: '100%'}}
              labelId="ofo-select-label"
              id="ofo-select"
              label="Platform"
              onChange={(e: SelectChangeEvent) => {
                console.log(e.target.value)
                var cred = new Credential({ofo: e.target.value});
                console.log(cred)
                dispatch(setCredential(cred));
              }}
            >
              {OfoList
                .map((o) => (
                  <MenuItem value={o.slug} key={o.slug}>{o.name}</MenuItem>
              ))}
            </Select> :
              <Grid item xs={12}>{OfoList.filter((o) => credential.ofo == o.slug)[0].name}</Grid>
            }
            { credential.ofo == 'ubereats' || credential.ofo == 'menu' ?
              <>
                <Grid item xs={12} mt='5px'><Divider /></Grid>
                <Grid item xs={12} mt='5px'>受注アプリ: {statusText(credential.app)}</Grid>
                <Grid item xs={12} sx={{m: '10px'}}>
                  <TextField sx={{width: '100%'}} id="id" label="Email" variant="filled"
                    defaultValue={ credential.app.id }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      var cred = new Credential({
                        app: {...credential.app, id: event.target.value},
                        web: credential.web,
                        store: credential.store,
                        ofo: credential.ofo,
                      });
                      dispatch(setCredential(cred));
                    }}/>
                </Grid>
                <Grid item xs={12} sx={{m: '10px'}}>
                  <TextField sx={{width: '100%'}} id="password" label="Password" variant="filled"
                    defaultValue={ credential.app.password }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      var cred = new Credential({
                        app: {...credential.app, password: event.target.value},
                        web: credential.web,
                        store: credential.store,
                        ofo: credential.ofo,
                      });
                      dispatch(setCredential(cred));
                    }}/>
                </Grid>
              </> : <></>}
              { credential.ofo == 'ubereats' || credential.ofo == 'wolt' ?
              <>
                <Grid item xs={12} mt='5px'><Divider /></Grid>
                <Grid item xs={12} mt='5px'>管理画面: {statusText(credential.web)}</Grid>
                { credential.ofo == 'wolt' ?
                <>
                  <Grid item xs={12} sx={{m: '10px'}}>
                    <TextField sx={{width: '100%'}} id="id" label="店舗URL" variant="filled"
                      defaultValue={ credential.store.url }
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        var cred = new Credential({
                          app: credential.app,
                          web: credential.web,
                          store: {...credential.store, url: event.target.value},
                          ofo: credential.ofo,
                        });
                        dispatch(setCredential(cred));
                      }}/>
                  </Grid>
                </> : <></>}
              </> : <></>}
              { credential.ofo == 'demaecan' ?
              <>
                <Grid item xs={12} mt='5px'><Divider /></Grid>
                <Grid item xs={12} mt='5px'>管理画面: {statusText(credential.web)}</Grid>
                <Grid item xs={12} sx={{m: '10px'}}>
                  <TextField sx={{width: '100%'}} id="code" label="Code" variant="filled"
                    defaultValue={ credential.web.code }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      var cred = new Credential({
                        app: credential.app,
                        web: {...credential.web, code: event.target.value},
                        store: credential.store,
                        ofo: credential.ofo,
                      });
                      dispatch(setCredential(cred));
                    }}/>
                </Grid>
                <Grid item xs={12} sx={{m: '10px'}}>
                  <TextField sx={{width: '100%'}} id="id" label="Email" variant="filled"
                    defaultValue={ credential.web.id }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      var cred = new Credential({
                        app: credential.app,
                        web: {...credential.web, id: event.target.value},
                        store: credential.store,
                        ofo: credential.ofo,
                      });
                      dispatch(setCredential(cred));
                    }}/>
                </Grid>
                <Grid item xs={12} sx={{m: '10px'}}>
                  <TextField sx={{width: '100%'}} id="password" label="Password" variant="filled"
                    defaultValue={ credential.web.password }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      var cred = new Credential({
                        app: credential.app,
                        web: {...credential.web, password: event.target.value},
                        store: credential.store,
                        ofo: credential.ofo,
                      });
                      dispatch(setCredential(cred));
                    }}/>
                </Grid>
              </> : <></>}
            <Grid item xs={12} mt='5px'><Divider /></Grid>
            { credential.store.name ?
                <Grid item xs={12}>{credential.store.name}</Grid>
              : stores.length > 1 ?
              <Select
                sx={{width: '100%'}}
                labelId="store-select-label"
                id="store-select"
                label="Select"
                onChange={handleStoreChange}
              >
                {stores
                  .map((s: Store) => (
                    <MenuItem value={s.id} key={s.id}>{s.name}</MenuItem>
                ))}
              </Select>
              : <></>
            }
            <Grid item xs={12} sx={{textAlign: 'center', mt: '10px'}}>
              <Button variant="contained" onClick={handleButton}>
                {loading ? 
                  <CircularProgress sx={{ color:'black', height:'30px', width:'30px' }} />
                  : <>連携</>
                }
              </Button>
            </Grid>
          </Grid>

          : brandLoading ? <CircularProgress sx={{m:'auto'}} /> :
            <Grid container mb='10px'>
              <Grid item xs={12} sx={{textAlign: 'center', mt: '10px'}}>
                新規ブランドを追加
              </Grid>
              <Grid item xs={12} sx={{textAlign: 'center', mt: '10px'}}>
                <TextField sx={{ width: '100%' }} id="brand-name" label="ブランド名" variant="filled"
                  value={ brandName }
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setBrandName(event.target.value)
                }}/>
                <Button
                  color="primary"
                  variant="contained"
                  sx={{ mt:'20px', p:'5px 40px' }}
                  onClick={() => {
                    dispatch(newBrand({
                      name: brandName,
                      location_uuid: location.uuid,
                    }));
                  }}
                >
                  追加
                </Button>
              </Grid>
              <Grid item xs={12} sx={{textAlign: 'center', mt: '10px'}}>
                または、既存のブランドを紐づけ
              </Grid>
              <Grid item xs={12} sx={{textAlign: 'center', mt: '10px'}}>
                <Select
                  sx={{width: '100%'}}
                  labelId="brand-select-label"
                  id="brand-select"
                  label="Brand"
                  onChange={(e: SelectChangeEvent) => {
                    dispatch(addBrand({
                      brand_uuid: e.target.value,
                      location_uuid: location.uuid,
                    }));
                  }}
                >
                  {!brands ? null : brands.filter((b: Brand) => !b.location_uuids.includes(location.uuid))
                    .map((b: Brand) => (
                      <MenuItem value={b.uuid} key={b.uuid}>{b.name}</MenuItem>
                  ))}
                </Select>
                { !credError ? null :
                  <Grid item xs={12} sx={{textAlign: 'center', mt: '10px'}}>
                    {credError}
                  </Grid>
                }
              </Grid>
            </Grid>
        }
      </Box>
    </>
  );
};

export default CredentialView;
import { useEffect, useState } from 'react';

import { AppBar, BottomNavigation, BottomNavigationAction, Box, Collapse, createTheme, Divider, Drawer, List, ListItemButton, ListItemIcon, ListItemText, Switch } from '@mui/material';
import { Location } from '../model/model';
import { KeyboardArrowUp, Person, SyncAlt, Logout, CategoryOutlined, ChevronLeft, ExpandLess, ExpandMore, HomeOutlined, Menu, MenuBookOutlined, StorefrontOutlined, BarChart } from '@mui/icons-material/';
import { auth } from '../firebase';
import { onAuthStateChanged, signOut } from "firebase/auth";
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { AppDispatch } from '../store/store';
import { useLocation, useNavigate } from 'react-router-dom';

import { getOrganization, resetUser, selectOrganization, selectUser } from '../store/modules/user';
import { getLocations, selectLocation, selectLocations, setLocation } from '../store/modules/locations';

const Navigation = () => {
  const path = useLocation().pathname;
  const [page, setPage] = useState(
    path == '/sales' ? 0 :
    path == '/credentials' ? 1 :
    path == '/menu' ? 2 :
    path == '/stock' ? 3 : 0
  );
  const goPage = (i: number) => {
    if (i == page) { return; }
    setPage(i);
    switch (i) {
      case 0:
        navigate('/sales');
        break;
      case 1:
        navigate('/credentials');
        break;
      case 2:
        navigate('/menu');
        break;
      case 3:
        navigate('/stock');
        break;
      default:
          break
    }
    setDrawerOpened(false);
  }


  const isDesktop: boolean = useMediaQuery({ minWidth: 600 })
	const [drawerOpened, setDrawerOpened] = useState(false);
	const [locationDrawerOpened, setLocationDrawerOpened] = useState(false);
  const [listOpened, setListOpened] = useState(true);
  const user = useSelector(selectUser);
  const organization = useSelector(selectOrganization);
  const location = useSelector(selectLocation);
  const locations: Location[] = useSelector(selectLocations);

  const handleClick = () => {
    setListOpened(!listOpened);
  };

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const signout = async () => {
    await signOut(auth);
    dispatch(resetUser());
    localStorage.removeItem('token');
  }

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      let token = localStorage.getItem('token');
      if (!user || !token) {
        navigate('/signin');
      } else if (!organization) {
        dispatch(getOrganization());
      } else {
        dispatch(getLocations(organization.uuid));
      }
    });
  }, [organization]);

  return (
    <>
      <Box sx={{
        position: 'fixed',
        zIndex: 1000,
        top: 0,
        left: 0,
        height: '44px',
        width: '100%',
        backgroundColor: 'white',
        display: 'flex',
      }}>
        <Box className='Button'
        onClick={() => setDrawerOpened(true)}>
        <Menu />
      </Box>
        <Box style={{
          width:'100%',
          fontSize: '20px',
          lineHeight: '44px',
          textAlign: 'center'
        }}>
          {location? location.name: ''}
        </Box>
        <Box className='Button'
        onClick={() => setLocationDrawerOpened(true)}>
          <SyncAlt />
        </Box>
      </Box>
      <Drawer
        anchor={isDesktop ? 'left' : 'top'}
        variant='persistent'
        open={drawerOpened}
        sx={{width: !isDesktop ? 0 : drawerOpened ? 180 : 0}}>
        <Box className='Button'
          sx={{
            display: 'flex',
            justifyContent: isDesktop ? 'end' : 'start',
            backgroundColor: 'white',
          }}
          onClick={() => setDrawerOpened(false)}>
            { isDesktop ? 
              <ChevronLeft /> :
              <KeyboardArrowUp />
            }
        </Box>
      <List sx={{ m: '0 20px'}}>
        {/* <h2 style={{ width: isDesktop ? '140px': '100%', fontSize: '20px', 'textAlign': 'center' }}>Records<br />Today</h2> */}
        <p style={{margin: '0 auto', width: isDesktop ? '140px': '100%', fontSize: '20px', 'textAlign': 'center' }}>{organization ? organization.name : ""}様</p>
      </List>
      {/* <Divider /> */}
      <List>
        <ListItemButton
          onClick={(event) => {
            event.stopPropagation();
            goPage(0);
          }}
          key='sales'
        >
          <ListItemIcon><BarChart /></ListItemIcon>
          <ListItemText primary="売上" />
        </ListItemButton>
      </List>
      <List>
        <ListItemButton
          onClick={(event) => {
            event.stopPropagation();
            goPage(1);
          }}
          key='credentials'
        >
          <ListItemIcon><StorefrontOutlined /></ListItemIcon>
          <ListItemText primary="アカウント" />
        </ListItemButton>
      </List>
      <List>
        <ListItemButton
          onClick={(event) => {
            event.stopPropagation();
            goPage(2);
          }}
          key='menu'
        >
          <ListItemIcon><MenuBookOutlined /></ListItemIcon>
          <ListItemText primary="メニュー" />
        </ListItemButton>
      </List>
      <List>
        <ListItemButton
          onClick={(event) => {
            event.stopPropagation();
            goPage(3);
          }}
          key='stock'
        >
          <ListItemIcon><CategoryOutlined /></ListItemIcon>
          <ListItemText primary="在庫管理" />
        </ListItemButton>
      </List>
      <List>
        <ListItemButton
          onClick={(event) => {
            event.stopPropagation();
            signout();
          }}
          key='signout'
        >
          <ListItemIcon><Logout /></ListItemIcon>
          <ListItemText primary="ログアウト" />
        </ListItemButton>
      </List>
      </Drawer>
      <Drawer
        anchor={'top'}
        variant='persistent'
        open={locationDrawerOpened}
        sx={{width: 0}}>
        <Box sx={{
            display: 'flex',
            justifyContent: 'end',
            height: '50px',
            backgroundColor: 'white',
          }}
          onClick={() => setLocationDrawerOpened(false)}>
            <KeyboardArrowUp style={{ width:'40px', height: '40px', margin: '5px' }} />
        </Box>
        <List sx={{ m: '0 20px'}}>
          <h2 style={{ width: '100%', fontSize: '20px', 'textAlign': 'center' }}>店舗を変更する</h2>
        </List>
        <Divider />
        { !locations ? null : locations.map((loc: Location) => (
          <List>
            <ListItemButton
              onClick={(event) => {
                dispatch(setLocation(loc.uuid));
                setLocationDrawerOpened(false);
              }}
              key='sales'
            >
              <ListItemText primary={loc.name} />
            </ListItemButton>
          </List>
        ))}
      
      </Drawer>
      <AppBar component={'footer'}>
      <BottomNavigation
        showLabels
        value={page}
        onChange={(event, newValue) => {
          goPage(newValue);
        }}
        sx={{position:'fixed', width:'100%', bottom:0, left:0, zIndex:1000, boxShadow: '0 10px 25px 0 rgba(0, 0, 0, .5)'}}
      >
        <BottomNavigationAction label="売上" icon={<BarChart />} />
        <BottomNavigationAction label="アカウント" icon={<StorefrontOutlined />} />
        <BottomNavigationAction label="メニュー" icon={<MenuBookOutlined />} />
        <BottomNavigationAction label="在庫管理" icon={<CategoryOutlined />} />
      </BottomNavigation>
      </AppBar>
    </>
  )
}

export default Navigation;